html,
body {
  height: 100%;
}

body > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
