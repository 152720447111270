.faqs-content {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Work Sans";
}

.faqs-content {
  --primary-color-main: #ce1462;
  --primary-color-dark: #900e44;
  --primary-color-light: #d74381;
  --secondary-color-main: #ffbc3f;
  --secondary-color-dark: #b23c2c;
  --secondary-color-light: #ffc965;
  --error-color-main: #ff563f;
  --error-color-dark: #c23c2c;
  --error-color-light: #ff7765;
  --grey-color-50: #fff;
  --grey-color-100: #f1f1f1;
  --grey-color-200: #d9d9d9;
  --grey-color-400: #b9b9b9;
  --grey-color-700: #00000080;
  --grey-color-800: #4a4a4a;
  --grey-color-900: #000;
  --btn-primary-color: var(--grey-color-900);
  --btn-secondary-color: var(--grey-color-200);
}

.faqs-content h1,
.faqs-content h2,
.faqs-content h3,
.faqs-content h4,
.faqs-content h5,
.faqs-content h6 {
  margin: 0;
  font-family: "Work Sans";
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.faqs-content h1 {
  font-weight: 300;
  font-size: 6rem;
  line-height: 1.167;
  font-weight: 800;
  line-height: 4.5rem;
  font-size: 4rem;
  letter-spacing: -0.08rem;
  margin-left: 10px;
  margin-right: 10px;
}

.faqs-content h2 {
  font-size: 3.75rem;
  line-height: 1.2;
  font-weight: 800;
  line-height: 3.5rem;
  font-size: 3rem;
  letter-spacing: -0.06rem;
  margin-left: 10px;
  margin-right: 10px;
}

.faqs-content h3 {
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.167;
  font-weight: 800;
  line-height: 2.5rem;
  font-size: 2rem;
  letter-spacing: -0.04rem;
}

.faqs-content h4 {
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  font-weight: 800;
  line-height: 2rem;
  font-size: 1.5rem;
  letter-spacing: -0.03rem;
  margin-left: 10px;
  margin-right: 10px;
}

.faqs-content h5 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  margin-left: 10px;
  margin-right: 10px;
}

.faqs-content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  margin-left: 10px;
  margin-right: 10px;
}

.faqs-content p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.faqs-content p,
.faqs-content a {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.faqs-content a {
  font-weight: bold;
  font-size: 1em;
  color: var(--grey-color-900);
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.faqs-content a:hover {
  text-decoration: none;
}

.faqs-content .d-block {
  display: block;
}

.faqs-content .mr-auto,
.faqs-content .mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.faqs-content .float-right {
  float: right !important;
}

.faqs-content .float-left {
  float: left !important;
}

.faqs-content .rounded {
  border-radius: 3px !important;
}

.faqs-content .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.faqs-content .o_web_client .o_content > .o_view_controller {
  direction: ltr;
}

.faqs-content .oe_form_field_html_text .o_readonly {
  word-wrap: break-word;
}

.faqs-content .o_field_widget,
.faqs-content .o_field_widget input.o_input {
  text-align: inherit;
}

.faqs-content .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: var(--btn-primary-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  min-width: 64px;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
}

.faqs-content .btn-lg,
.faqs-content .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.35416667rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.faqs-content .btn:hover,
.faqs-content .btn:focus {
  text-decoration: none;
}

.faqs-content .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.faqs-content .rounded-circle {
  border-radius: 50% !important;
}

.faqs-content .btn.rounded-circle {
  border-radius: 1000px !important;
}

.faqs-content .btn-alpha:not(.flat) {
  color: #fff !important;
  background-color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
}

.faqs-content .btn-alpha:not(.flat):hover {
  color: #fff !important;
  background-color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
}

.faqs-content .btn-beta:not(.flat) {
  color: var(--btn-primary-color) !important;
  background-color: var(--btn-secondary-color) !important;
  border-color: var(--btn-secondary-color) !important;
}

.faqs-content .btn-beta:not(.flat):hover {
  color: var(--btn-primary-color) !important;
  background-color: var(--btn-secondary-color) !important;
  border-color: var(--btn-secondary-color) !important;
}

.faqs-content .btn-outline-beta,
.faqs-content .btn-outline-alpha {
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
  border: 1px solid !important;
  color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
  padding: 16px !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  border-radius: 8px !important;
}

.faqs-content .btn-outline-beta:hover,
.faqs-content .btn-outline-alpha:hover {
  color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
}

.faqs-content .btn.flat {
  color: var(--btn-primary-color) !important;
  box-shadow: none !important;
  padding: 16px !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  border-radius: 8px !important;
  position: relative !important;
}

.faqs-content .btn.flat:hover {
  text-decoration: underline !important;
}

.faqs-content .btn.flat::after {
  content: "→";
  line-height: 0;
  font-size: 2.2em;
  position: absolute;
  right: -0.35em;
  top: 36%;
  font-family: monospace;
}

.faqs-content .btn-sm,
.faqs-content .btn-group-sm > .btn {
  color: var(--primary-color-main);
  padding: 0.25rem 0.5rem;
  font-size: 0.94791667rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.faqs-content .btn-sm:hover,
.faqs-content .btn-group-sm > .btn:hover {
  color: var(--primary-color-dark) !important;
}

.faqs-content .table-bordered {
  border: 1px solid var(--grey-color-200);
}

.faqs-content table {
  border-collapse: collapse;
}

.faqs-content .table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.faqs-content .table th,
.faqs-content .table td {
  border-top: 1px solid var(--grey-color-200);
  padding: 0.75rem;
  vertical-align: top;
}

.faqs-content .table-bordered th,
.faqs-content .table-bordered td {
  border: 1px solid var(--grey-color-200);
}

.faqs-content ol,
.faqs-content ul,
.faqs-content dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.faqs-content img {
  vertical-align: middle;
  border-style: none;
}

.faqs-content .img-fluid {
  max-width: 100%;
  height: auto;
}

.faqs-content .img-fluid:not(.float-left):not(.float-right) {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.o_we_selected_image,
.fa.o_we_selected_image::before {
  background: rgba(150, 150, 220, 0.3);
  outline: 3px solid rgba(150, 150, 220, 0.3);
}

.faqs-search-input > input {
  padding: 16.5px 14px;
  padding-right: 0px;
}
